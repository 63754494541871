.rc-collapse {
    @apply grid grid-cols-1 gap-4 bg-transparent border-none; 
}

.rc-collapse > .rc-collapse-item:first-child {
    border-top: 1px solid;
}

.rc-collapse-item {
    @apply border border-gray-400 rounded-xl;
}

.rc-collapse-expand-icon {
    @apply relative w-5 h-5;
}

.rc-collapse-expand-icon::before {
    content: '';
    @apply absolute top-0 left-1/2 -translate-x-1/2 h-full w-0.5 bg-black transition-all duration-500;
}

.rc-collapse-expand-icon::after {
    content: '';
    @apply absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-0.5 w-full bg-black;
}

.rc-collapse-item-active .rc-collapse-expand-icon::before {
    @apply -translate-x-1/2 rotate-90;
}

.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow,
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
    @apply hidden;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
    @apply flex items-center flex-row-reverse p-5; 
}

.rc-collapse-header-text {
    @apply text-left mr-auto text-xl font-medium text-black max-w-[calc(100%-25px)] max-[767.98px]:text-lg;
}

.rc-collapse-content {
    @apply bg-transparent;
}
