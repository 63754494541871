@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-sans text-lg text-black scroll-smooth max-[767.98px]:text-base;
    }

    .container {
        @apply !w-[90%] !max-w-[1140px] mx-auto;
    }

    .container-lg {
        @apply !w-[90%] !max-w-[1440px] mx-auto;
    }

    h1, 
    .heading {
        @apply text-6xl font-bold max-[1440px]:text-4xl;
    }

    h2 {
        @apply text-4xl font-bold uppercase max-[1440px]:text-2xl;
    }

    .bg-gray-gradient {
        background: linear-gradient(180deg, #838383 0%, #9F9F9F 100%);
    }
}


@layer components {
    .social__media {
        @apply flex items-center gap-3;
    }

    .social__link {
        @apply flex items-center justify-center w-10 h-10 transition-all hover:opacity-70;
    }

    .social__link img {
        @apply w-full h-full;
    }
}